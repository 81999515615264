@import "variables";
@import "mixins";

body {
  width: 100%;
  height: 100%;
  font-family: "Lora", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: $col_text;
  background: url(../images/background.jpg) repeat center scroll;
  background-color: black;
}

html {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  text-transform: uppercase;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  color: $col_text;
}

h2 {
  margin-bottom: 20px;
}

p {
  margin: 0 0 25px;
  font-size: 18px;
  line-height: 1.5;
  @media(min-width: 768px) {
    margin: 0 0 35px;
    font-size: 20px;
    line-height: 1.6;
  }
}

a {
  color: $col_link;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  &:hover,
  &:focus {
    text-decoration: none;
    color: darken($col_link, 20%);
  }
}

.light {
  font-weight: 400;
}

.navbar-custom {
  margin-bottom: 0;
  border-bottom: 1px solid rgba(white, 0.3);
  text-transform: uppercase;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: black;
  .navbar-toggle {
    padding: 4px 6px;
    font-size: 16px;
    color: white;
    &:focus,
    &:active {
      outline: none;
    }
  }
  .navbar-brand {
    font-weight: 700;
    &:focus {
      outline: none;
    }
  }
  a {
    color: $col_link;
  }
  &.top-nav-collapse {
    a {
      color: $col_white;
    }
  }
  .nav {
    li {
      a {
        -webkit-transition: background .3s ease-in-out;
        -moz-transition: background .3s ease-in-out;
        transition: background .3s ease-in-out;
        &:hover {
          color: rgba($col_link, 0.8);
          outline: none;
          background-color: transparent;
        }
        &:focus,
        &:active {
          outline: none;
          background-color: transparent;
        }
      }
      &.active {
        outline: none;
        a {
          background-color: rgba(white, 0.3);
          &:hover {
            color: $col_link;
          }
        }
      }
    }
  }
  @media(min-width: 768px) {
    padding: 20px 0;
    border-bottom: none;
    letter-spacing: 1px;
    background: transparent;
    -webkit-transition: background .5s ease-in-out, padding .5s ease-in-out;
    -moz-transition: background .5s ease-in-out, padding .5s ease-in-out;
    transition: background .5s ease-in-out, padding .5s ease-in-out;
    &.top-nav-collapse {
      padding: 0;
      background: black;
      border-bottom: 1px solid rgba(white, 0.3);
    }
  }
}

.footer-custom {
  background-color: rgba(255, 255, 255, 0.5);
}

.intro {
  display: table;
  width: 100%;
  height: auto;
  padding: 100px 0;
  text-align: center;
  color: $col_text;
  //background: url(../images/background.png) no-repeat bottom center scroll;
  //background-color: black;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  .intro-body {
    display: table-cell;
    vertical-align: middle;
    .brand-heading {
      font-size: 40px;
    }
    .intro-text {
      font-size: 18px;
    }
  }
  @media(min-width: 768px) {
    height: 100%;
    padding: 0;
    .intro-body {
      .brand-heading {
        font-size: 100px;
      }
      .intro-text {
        font-size: 26px;
      }
    }
  }
}

.btn-circle {
  width: 70px;
  height: 70px;
  margin-top: 15px;
  padding: 7px 16px;
  border: 2px solid $col_link;
  border-radius: 100% !important;
  font-size: 40px;
  color: $col_link;
  background: transparent;
  -webkit-transition: background .3s ease-in-out;
  -moz-transition: background .3s ease-in-out;
  transition: background .3s ease-in-out;
  &:hover,
  &:focus {
    outline: none;
    color: $col_link;
    background: rgba(white, 0.1);
  }
  i.animated {
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-duration: 1s;
    -moz-transition-property: -moz-transform;
    -moz-transition-duration: 1s;
  }
  &:hover {
    i.animated {
      -webkit-animation-name: pulse;
      -moz-animation-name: pulse;
      -webkit-animation-duration: 1.5s;
      -moz-animation-duration: 1.5s;
      -webkit-animation-iteration-count: infinite;
      -moz-animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
    }
  }
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes pulse {
  from {
    -moz-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -moz-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -moz-transform: scale(1);
    transform: scale(1);
  }
}

.content-section {
  padding-top: 50px;
  padding-bottom: 150px;
}

.download-section {
  width: 100%;
  padding: 50px 0;
  color: white;
  background: url(../img/downloads-bg.jpg) no-repeat center center scroll;
  background-color: black;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

#map {
  width: 100%;
  height: 400px;
}

@media(min-width: 767px) {
  .content-section {
    padding-top: 150px;
    padding-bottom: 350px;
  }

  .download-section {
    padding: 100px 0;
  }

  #map {
    height: 600px;
  }
}

.btn {
  text-transform: uppercase;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  border-radius: 0;
}

.btn-default {
  border: 1px solid $col_link;
  color: $col_link;
  background-color: transparent;
  &:hover,
  &:focus {
    border: 1px solid $col_link;
    outline: none;
    color: black;
    background-color: $col_link;
  }
}

ul.banner-social-buttons {
  margin-top: 0;
  @media(max-width: 1199px) {
    margin-top: 15px;
  }
  @media(max-width: 767px) {
    li {
      display: block;
      margin-bottom: 20px;
      padding: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

footer {
  padding: 0;
  p {
    margin: 0;
  }
}

::-moz-selection {
  text-shadow: none;
  background: #fcfcfc;
  background: rgba(white, 0.2);
}

::selection {
  text-shadow: none;
  background: #fcfcfc;
  background: rgba(white, 0.2);
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

body {
  -webkit-tap-highlight-color: rgba(white, 0.2);
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.thirty_logo {
  margin: 10px 0;
  width: 90%;
}

@media (min-width: 768px) {
  .thirty_logo {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .thirty_logo {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .thirty_logo {
    width: 1170px;
  }
}

#information {
  .weekday {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }

  .month {
    font-size: 7.5rem;
    line-height: 6.5rem;
  }

  .day {
    font-size: 4rem;
    line-height: 6.25rem;
  }

  .year {
    font-size: 4.25rem;
    text-decoration: overline underline;
    line-height: 6.25rem;
  }

  .time {
    font-size: 3.5rem;
  }
}

#contact {
  ul {
    text-align: left;
  }

  .info {
    font-size: 2rem;
  }

  .description {
    font-style: italic;
  }
}

#lamps {
  position: fixed;
  top: 0;
  left: 0;
}

.banner {
  #Velo_1 {
    display: none;
  }
  #Mandli_stehend {
    display: none;
  }
  #Mandli_wassersprung {
    display: none;
  }
  #Mandli_schwimmend_1 {
    display: none;
  }
  #Mandli_schwimmend_2 {
    display: none;
  }
  #Mandli_auf_Velo_1 {
    display: none;
  }
  #Mandli_auf_Velo_2 {
    display: none;
  }
  #Velo_2 {
    display: none;
  }
  #Läufer_1 {
    display: none;
  }
  #Läufer_2 {
    display: none;
  }
  #Party_1 {
    display: none;
  }
  #Party_2 {
    display: none;
  }
}

.banner0 {
  #Velo_1 {
    display: block;
  }
  #Mandli_stehend {
    display: block;
  }
}

.banner1 {
  #Velo_1 {
    display: block;
  }

  #Mandli_wassersprung {
    display: block;
  }
}

.banner2 {
  #Velo_1 {
    display: block;
  }
  #Mandli_schwimmend_1 {
    display: block;
  }
}

.banner3 {
  #Velo_1 {
    display: block;
  }
  #Mandli_schwimmend_2 {
    display: block;
  }
}

.banner4 {
  #Velo_1 {
    display: block;
  }
  #Mandli_auf_Velo_1 {
    display: block;
  }
}

.banner5 {
  #Mandli_auf_Velo_2 {
    display: block;
  }
}

.banner6 {
  #Velo_2 {
    display: block;
  }
  #Läufer_1 {
    display: block;
  }
}

.banner7 {
  #Velo_2 {
    display: block;
  }
  #Läufer_2 {
    display: block;
  }
}

.banner8 {
  #Velo_2 {
    display: block;
  }
  #Party_1 {
    display: block;
  }
}

.banner9 {
  #Velo_2 {
    display: block;
  }
  #Party_2 {
    display: block;
  }
}